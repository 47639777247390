<template>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><a id="title2">用户管理</a></li>
            <li class="breadcrumb-item active" id="title3">编辑用户</li>
          </ol>
        </div>
        <h4 class="page-title" id="title1">编辑用户</h4>
      </div>
    </div>
    <el-form :model="formData" label-width="20%" ref="formDataRef" :rules="addrules" align="left">

      <el-form-item label="姓名" prop="uname">
        <el-input v-model="formData.uname" placeholder="姓名" style="width:50%;"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-radio-group v-model="formData.sex">
          <el-radio label="男">
            男
          </el-radio>
          <el-radio label="女">
            女
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="手机号码" prop="uphone">
        <el-input v-model="formData.uphone" placeholder="手机号码" style="width:50%;"></el-input>
      </el-form-item>
      <el-form-item label="联系地址" prop="uaddress">
        <el-input v-model="formData.uaddress" placeholder="联系地址" style="width:50%;"></el-input>
      </el-form-item>

      <el-form-item label="用户状态" prop="mflag">
        <el-radio-group v-model="formData.mflag">
          <el-radio label="正常">
            正常
          </el-radio>
          <el-radio label="禁用">
            禁用
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="save" :loading="btnLoading" icon="el-icon-upload">提 交</el-button>
        <el-button type="info" size="small" @click="goBack" icon="el-icon-back">返 回</el-button>
      </el-form-item>
    </el-form>
    <el-dialog v-model="uploadVisible" title="附件上传" custom-class="el-dialog-widthSmall" @close="closeDialog">
      <div>
        <b>请选择文件（png,jpg格式）进行上传！&emsp;</b>
      </div>
      <el-upload action="http://localhost:8088/gouwu/api/common/uploadFile" style="
            margin: auto;
            margin-top: 10px;
            border: 1px solid #dcdfe6;
            border-radius: 4px;
          " drag :limit="1" :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList"
        :on-exceed="handleExceed" :auto-upload="false" name="file" :on-change="fileListChange">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将数据文件拖到此处，或<em>点击上传</em>
        </div>
        <div class="el-upload__tip">
          <div style="display: inline; color: #d70000; font-size: 14px" class="uploadFileWarning" id="uploadFileWarning">
          </div>
        </div>
      </el-upload>
      <span class="dialog-footer">
        <el-button @click="hideUpload">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">提 交</el-button>
      </span>
    </el-dialog>


  </div>
</template>
<script>
import request, { base } from "../../../utils/http";

export default {
  name: 'MembersEdit',
  components: {

  },
  data() {
    return {
      id: '',
      isClear: false,
      uploadVisible: false,
      btnLoading: false, //保存按钮加载状态     
      formData: {}, //表单数据    


    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDatas();
  },


  methods: {

    //获取列表数据
    getDatas() {
      let para = {
      };
      this.listLoading = true;
      let url = base + "/members/get?id=" + this.id;
      request.post(url, para).then((res) => {
        this.formData = JSON.parse(JSON.stringify(res.resdata));
        this.listLoading = false;


      });
    },

    // 添加
    save() {
      this.$refs["formDataRef"].validate((valid) => { //验证表单
        if (valid) {
          let url = base + "/members/update";
          this.btnLoading = true;

          request.post(url, this.formData).then((res) => { //发送请求         
            if (res.code == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
                offset: 320,
              });
              this.$router.push({
                path: "/MembersManage",
              });
            } else {
              this.$message({
                message: res.msg,
                type: "error",
                offset: 320,
              });
            }
            this.btnLoading = false;
          });
        }

      });
    },

    // 返回
    goBack() {
      this.$router.push({
        path: "/MembersManage",
      });
    },


    //显示上传框
    showUpload() {
      this.uploadVisible = true;
    },

    //隐藏上传框
    hideUpload() {
      this.uploadVisible = false;
    },
    //上传
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message({
        duration: 1000,
        message: "只能上传一个文件",
        type: "error",
        offset: 320,
      });
    },
    // 判断上传文件后缀
    fileListChange(file, fileList) {
      let extendFileName = "png,jpg";
      let extendFileNames = extendFileName.split(",");
      let regExpRules = [];
      for (let i = 0; i < extendFileNames.length; i++) {
        regExpRules.push(
          new RegExp("(.*).(" + extendFileNames[i] + ")$", "gim")
        );
      }
      let fileNames = [];
      let files = [];
      let that = this;
      fileList.forEach(function(key, val) {
        let ret = false;
        for (let i = 0; i < regExpRules.length; i++) {
          ret = ret || regExpRules[i].test(key["name"]);
        }
        if (!ret) {
          console.log(key["name"] + ":" + ret);
          that.$message({
            duration: 1000,
            message: "上传的文件后缀必须为" + extendFileName + "格式！",
            type: "error",
            offset: 320,
          });
          return false;
        }
        if (fileNames.indexOf(key["name"]) != -1) {
          that.$message({
            duration: 1000,
            message: "上传的文件重复！",
            type: "error",
            offset: 320,
          });
          return false;
        }
        //只能上传一个文件，用最后上传的覆盖
        if (!that.multiFiles) {
          files = [];
          fileNames = [];
        }
        files.push(key);
        fileNames.push(key["name"]);
        if (fileNames !== "") {
          // $('#uploadMad .el-upload-dragger').css('border-color', '#409eff');
        }
        //$(".uploadFileWarning").text("");
      });
      this.files = fileNames;
      this.fileList = files;
    },
    /**
     * 确认按钮
     */
    handleConfirm() {
      let filePath = this.fileList;
      if (filePath.length === 0) {
        this.$message({
          duration: 1000,
          message: "请选择文件！",
          type: "error",
          offset: 320,
        });
        return false;
      }
      let formData = new FormData();
      this.fileList.forEach((file) => {
        formData.append("file", file.raw, file.raw.name);
      });
      let url = base + "/common/uploadFile";
      console.log("url=" + url);
      request.post(url, formData).then((res) => {
        console.log(res);
        let furl = res.resdata.filePath;
        this.formData.uavatar = furl;  // 上传文件的路径
        this.hideUpload();
        console.log(res);
      });
    },



  },
}

</script>
<style scoped></style>
 

